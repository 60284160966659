<template>
  <div id="searchbar" ref="searchbar">
    <div class="row middle-sm pt25 pb0">
      <div class="col-xs-12">
        <no-ssr>
          <ul>
            <li v-for="category in categoryList" :key="category.id" class="noness">
              <h3 class="searchbar_categories">
                <span class="cl-accent" @click="redirectTo(category.link)">
                  <div v-html="category.name" />
                </span>
              </h3>
            </li>

            <!-- If there is no category, just maintain same spacing -->
            <li v-if="categoryList.length === 0">
              <h3 class="searchbar_categories">
                <span class="cl-accent">
                  <div />
                </span>
              </h3>
            </li>
          </ul>
        </no-ssr>
      </div>
    </div>
    <div class="row middle-xs">
      <div class="category-select-dropdown col-xs-12 col-sm-12 col-md-6 col-lg boxie pl10" :class="{ highlight_element: isHighlited.selectOne }">
        <span class="upper_line">Vybavení</span>

        <v-select class="style-chooser"
                  v-model="searchCategory"
                  :value="searchCategory"
                  @select="searchAfterCategorySelected(); focusSearchInput(200)"
                  @open="isSelectOpen(true,&quot;selectOne&quot;)"
                  @close="isSelectOpen(false,&quot;selectOne&quot;)"
                  :is-first-option-selected-by-default="shouldFirstOptionBeSelected"
                  :options="vueSelectCategories"
                  close-on-select
                  label="name"
                  placeholder="Co půjčujete?"
                  ref="categoryPicker"
        >
          <div slot="no-options">
            {{ noCategoriesText }}
          </div>
          <template v-slot:option="option">
            <span class="vuemenu" :class="`level-` + option.classLevel">
              {{ option.name }}
            </span>
          </template>
        </v-select>
      </div>
      <div class="col-xs-12 col-sm-6 boxie col-lg col-md-6 hide-xs hide-sm hide-lg hide-xl pl10" :class="{ highlight_element: isHighlited.selectTwo }">
        <span class="upper_line">Hledat</span>
        <input class="searchInput vs__search" type="text" v-model="searchString" :placeholder="$t('Enter the search term')">
      </div>
      <div class="col-xs-5 col-md-6 col-lg-2 pl5 boxie"
           :class="{ 'highlight_element': isHighlited.dateOne, 'disabled': !isCartEmpty }"
           @click="notifyDates"
      >
        <span class="upper_line pl3">Datum od</span>
        <!--        <span v-else class="upper_line pl3">Datum</span>-->
        <date-pick
          @selected="isSelectOpen($event, 'dateOne'); focusSearchInput()"
          v-model="dateFrom"
          :key="compRefreshKey"
          :display-format="'DD.MM.YYYY'"
          :pick-time="false"
          :format="'YYYY-MM-DDThh:mm:ss'"
          :is-date-disabled="isDatePast"
          :editable="isCartEmpty"
          :placeholder-text="'OD ?'"
        />
      </div>
      <div class="col-xs-5 col-md-5 col-lg-2 pl5 boxie"
           :class="{ 'highlight_element': isHighlited.dateTwo, 'disabled': !isCartEmpty }"
           @click="notifyDates"
      >
        <span class="upper_line pl3">Datum do</span>
        <!--        <span v-else class="upper_line pl3">Datum</span>-->
        <div>
          <date-pick
            @selected="isSelectOpen($event,'dateTwo'); focusSearchInput()"
            v-model="dateTo"
            :key="compRefreshKey"
            :display-format="'DD.MM.YYYY'"
            :pick-time="false"
            :format="'YYYY-MM-DDThh:mm:ss'"
            :is-date-disabled="isDateToAvailable"
            :start-period="getDateToStartPeriod()"
            :editable="isCartEmpty"
            :placeholder-text="'DO ?'"
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-10 col-lg boxie hide-xs hide-sm hide-md pl10" :class="{ highlight_element: isHighlited.selectTwo }">
        <span class="upper_line">Hledat</span>
        <input class="searchInput vs__search" type="text" v-model="searchString" :placeholder="$t('Enter the search term')" ref="searchInput" @keyup.enter="triggerSearchIfConditionsMet">
      </div>
      <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1 end-md">
        <button
          type="button"
          ref="searchButton"
          :aria-label="$t('Open search panel')"
          class="bg-cl-transparent brdr-none inline-flex s-button"
          id="search-button"
          :class="{ 'disabled': isSearchButtonDisabled }"
          @click="search()"
          data-testid="openSearchPanel"
          :disabled="isSearchButtonDisabled"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="47" viewBox="0 0 48 47"><g><g><path class="search-btn-fill" fill="#fc3711" d="M47.428 23.33c0 12.864-10.429 23.293-23.294 23.293C11.268 46.623.84 36.194.84 23.33S11.27.035 24.134.035C37 .035 47.428 10.465 47.428 23.33z" /></g><g><path fill="#fff" d="M12.248 21.227c0-5.216 4.243-9.46 9.46-9.46 5.217 0 9.46 4.244 9.46 9.46 0 5.216-4.243 9.46-9.46 9.46-5.217 0-9.46-4.244-9.46-9.46zm2.102 0c0 4.057 3.301 7.358 7.358 7.358 4.057 0 7.358-3.3 7.358-7.358 0-4.057-3.301-7.358-7.358-7.358-4.057 0-7.358 3.301-7.358 7.358z" /></g><g><path fill="#fff" d="M34.969 34.892c-.27 0-.538-.103-.743-.308l-6.792-6.792a1.05 1.05 0 1 1 1.486-1.487l6.792 6.792a1.05 1.05 0 0 1-.743 1.795z" /></g></g></svg>
        </button>
      </div>
    </div>
    <div v-if="subfilters" class="row middle-sm pt15">
      <div class="col-sm-12">
        <search-bar-filters
          @partnerInfoClick="$emit('partnerInfoClick')"
          :selected-category="searchCategory"
          :filters="filters"
          :current-filters="getModifiedCurrentFilters()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import SearchBarFilters from './SearchBarFilters'
import vSelect from '../vselect/components/Select.vue'
import {reservationDateLimiter, isDateShown} from '../helpers/reservationDateLimiter'
import { isServer } from '@vue-storefront/core/helpers'
import DatePick from './DatePick'
import dayjs from 'dayjs';
import gsap from 'gsap';
import { AVAILABILITY_TYPE, PARTNER_TYPE, SEARCH_STRING_TYPE } from '../../../../../../constants/FilterVariantTypes';
import FixedFilters from '@vue-storefront/core/mixins/FixedFilters';
import config from 'config'
import NoSSR from 'vue-no-ssr'

export default {
  name: 'SearchBar',
  components: {
    vSelect,
    DatePick,
    SearchBarFilters,
    'no-ssr': NoSSR
  },
  props: {
    currentCategory: {},
    filters: {},
    subfilters: Boolean,
    filterOnSelect: false,
    categoryList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [FixedFilters],
  data () {
    return {
      compRefreshKey: 0, // helps with refreshing components
      searchCategory: this.currentCategory,
      vueSelectCategories: [],
      isHighlited: {
        selectOne: false,
        selectTwo: false,
        dateOne: false,
        dateTwo: false
      },
      winterNotification: false,
      searchString: null,
      test: 'test'
    }
  },
  computed: {
    ...mapState({
      // submenu: state => state.ui.submenu, TODO
      currentUser: state => state.user.current
    }),
    ...mapGetters({
      isCartEmpty: 'cart/isCartEmpty',
      getCategories: 'category/getCategories',
      getCurrentFilters: 'category-next/getCurrentFilters',
      getCurrentSearchQuery: 'category-next/getCurrentSearchQuery',
      getSBCategories: 'searchbarCategories/getCategories',
      getSource: 'cart/getSource',
      getReservations: 'reservations/getCurrentDates'
    }),
    dateFrom: {
      get () {
        return this.$store.state.reservations.dateFrom;
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateFrom', date)
      }
    },
    dateTo: {
      get () {
        return this.$store.state.reservations.dateTo || '';
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateTo', date)
      }
    },
    visibleCategories () {
      return this.categories.filter(category => {
        return category.product_count > 0 || category.children_count > 0
      })
    },
    isCurrentMenuShowed () {
      return !this.getSubmenu || !this.getSubmenu.depth;
    },
    isThankYouPage () {
      return this.$store.state.checkout.isThankYouPage ? this.$store.state.checkout.isThankYouPage : false;
    },
    noOptionsText () {
      return this.$t('Sorry, no matching options.')
    },
    noCategoriesText () {
      return this.$t('Sorry, no matching categories.')
    },
    searchBarTooltip () {
      if (this.searchString !== null && this.searchCategory === null) {
        return true
      } else if (this.searchString !== null && this.searchCategory === '') {
        return true
      } else {
        return false
      }
    },
    shouldFirstOptionBeSelected () {
      return !this.searchCategory;
    },
    isSearchButtonDisabled () {
      const { searchCategory, dateFrom, dateTo } = this;
      return !searchCategory || (!!dateFrom !== !!dateTo);
    }
  },
  async created () {
    if (!isServer) {
      window.removeEventListener('load', this.checkIfDatesAreIncludedInresults)
    }
  },
  beforeUnmount () {
    window.removeEventListener('load', this.checkIfDatesAreIncludedInresults)
  },
  async mounted () {
    await this.$store.dispatch('searchbarCategories/fetchCategories');
    this.vueSelectCategories = [...this.getSBCategories];
    this.syncCategory();
    this.searchString = this.$route.query['searchString'] ? this.$route.query['searchString'] : null;
    this.checkIfDatesAreIncludedInresults();
    if (this.$route.query.dateFrom) {
      this.dateFrom = this.$route.query.dateFrom;
    }

    if (this.$route.query.dateTo) {
      this.dateTo = this.$route.query.dateTo;
    }
  },
  methods: {
    triggerSearchIfConditionsMet () {
      if (!this.isSearchButtonDisabled) {
        this.$refs.searchButton.click();
      }
    },
    focusSearchInput (timeout) {
      timeout
        ? setTimeout(() => {
          this.$refs.searchInput.focus();
        }, timeout)
        : this.$refs.searchInput.focus()
    },
    getDateToStartPeriod () {
      const FORMAT = 'YYYY-MM-DDThh:mm:ss'
      const date = this.dateTo ? dayjs(this.dateTo, FORMAT)
        : this.dateFrom ? dayjs(this.dateFrom, FORMAT) : dayjs()
      return {month: date.month(), year: date.year()}
    },
    search () {
      this.redirectTo(this.searchCategory.slug);
    },
    areDatesSelected () {
      if (!!this.dateFrom && !!this.dateTo) {
        return true
      } else {
        return false
      }
    },
    getModifiedCurrentFilters () {
      const partner = this.getSource
      if (!this.isCartEmpty && partner) {
        const filters = {...this.getCurrentFilters}
        filters[PARTNER_TYPE] = [{type: PARTNER_TYPE, id: partner.id, label: partner.name, attribute_code: PARTNER_TYPE}]
        return filters
      }
      return this.getCurrentFilters
    },
    redirectTo (path) {
      const fixedUrlQuery = this.getFixedUrlQuery();
      const query = { ...this.$route.query, ...fixedUrlQuery };

      if (this.searchString) {
        query[SEARCH_STRING_TYPE] = this.searchString;
      } else {
        delete query[SEARCH_STRING_TYPE];
      }
      // prevent duplicated navigation
      if (this.$route.path === `/${path}` && query[SEARCH_STRING_TYPE] === this.$route.query[SEARCH_STRING_TYPE]) {
        return;
      }
      this.$router.push({ path, query }).catch(() => {});
    },
    notifyDates () {
      if (!this.isCartEmpty) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t(
            'Before changing your dates you need to either finish or cancel your shopping.'
          ),
          action1: { label: this.$t('OK') }
        })
      }
    },
    isSelectOpen (state, elName) {
      this.isHighlited[elName] = state
    },
    syncCategory () {
      if (this.currentCategory !== null) {
        this.searchCategory = this.currentCategory
      } else {
        this.searchCategory = ''
      }
    },
    isDateToAvailable (date) {
      return isDateShown(date, this.dateFrom)
    },
    areSelectedDatesValid () {
      const dateFrom = dayjs(this.dateFrom)
      const dateTo = dayjs(this.dateTo)
      const now = dayjs()
      return !(now.isAfter(dateFrom) || now.isAfter(dateTo) || dateFrom.isAfter(dateTo))
    },
    isDatePast (date) {
      const now = dayjs()
      const individualDate = dayjs(date)
      const selectedDateTo = dayjs(this.dateTo, 'YYYY-MM-DD-HH-mm')
      // cap how far can be selectable
      const isTooFuture = reservationDateLimiter(date);

      if ((individualDate < now) || (this.dateTo !== '' && date > selectedDateTo) || isTooFuture) {
        return true
      } else {
        return false
      }
    },
    checkIfDatesAreIncludedInresults () {
      if (!this.getCurrentFilters[AVAILABILITY_TYPE] && this.areDatesSelected()) {
        this.redirectTo(this.$route.path)
      }
      if (this.getCurrentFilters[AVAILABILITY_TYPE] && !this.areDatesSelected()) {
        this.dateFrom = this.getCurrentFilters[AVAILABILITY_TYPE]?.from
        this.dateTo = this.getCurrentFilters[AVAILABILITY_TYPE]?.to
      }
    },
    handleSearchStringChanged () {
      if (this.filterOnSelect || !this.searchString) {
        this.search();
      }
    },
    updateReservationDates () {
      const updatedReservationFilter = { id: '', label: '', type: AVAILABILITY_TYPE, from: this.dateFrom, to: this.dateTo };
      if ((this.dateTo === '' && this.dateFrom === '') || !this.areSelectedDatesValid()) {
        this.$store.dispatch('category-next/switchSearchFilters', [updatedReservationFilter]);
        return;
      }
      if (!!this.dateTo && !!this.dateFrom) {
        this.$store.dispatch('category-next/switchSearchFilters', [updatedReservationFilter])
        this.animateSearchButton();
      }
    },
    animateSearchButton () {
      if (this.currentCategory === null) return
      // gsap.fromTo('#search-button', {scale: 0.8}, {scale: 1.0, duration: 1});

      const tl = gsap.timeline() // create the timeline
      tl.from('#search-button', {scale: 1, rotation: 0, duration: 0})
        .to('#search-button', {scale: 1.1, rotation: -180, duration: 0.7, ease: 'power4'})
        .to('#search-button', {scale: 1, rotation: 0, duration: 0.3, ease: 'bounce'})
    },
    matchCategory (cat) {
      const matchCatArr = this.vueSelectCategories.filter(category => category.id === cat.id)
      const matchCat = matchCatArr[0]
      return matchCat
    },
    searchAfterCategorySelected () {
      if (this.filterOnSelect) {
        this.search();
      }
    }
  },
  watch: {
    searchString () {
      if (!this.searchString) {
        this.search();
      }
    },
    currentCategory (val) {
      // TODO better handling of externally selected categories in dropdown. There is a bug RN.
      const matchCat = this.matchCategory(val)
      // Logger.debug('currentCategory', 'cat-service', val)()
      this.searchCategory = matchCat
    },
    dateFrom (val) {
      this.compRefreshKey += 1;
      this.updateReservationDates();
    },
    dateTo (val) {
      this.compRefreshKey += 1;
      this.updateReservationDates()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchInput {
  display: block;
  color: #333;
  opacity: 1;
  width: 100%;

  &::placeholder {
    color: var(--gray);
    opacity: 0.8;
  }

  border: none;
  &:focus {
    border: none;
    outline: none;
    width: 100% !important;
  }

}

ul {
  text-decoration: none;
  margin:0px;
  padding:0px;
  padding-left: 5px;
  @media only screen and (max-width: 575px) {
    padding-bottom: 5px;
  }
}
li.noness {
  display: block;
  float: left;
  padding-right: 20px;
  @media only screen and (max-width: 575px) {
    float: none;
    padding-right: 0px;
  }
}
.s-button {
  order: none;
  padding: 0px;
  margin: 0px;
  float: right;
}

@media only screen and (max-width: 575px) {
  .hide-xs {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hide-sm {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hide-md {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hide-lg {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .hide-xl {
    display: none;
  }
}
.tooltip {
  position: relative;
  top: -100px;
  background-color: #fff
}
.tooltip > col > i{
  font-size: 19px;
}
.flex-tool-tip {
  display:flex
}

</style>

<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-icon-hover: color(secondary, $colors-background);
$color-matterhorn: color(matterhorn);
$color-partner: color(partner);

.search-btn-fill {
  fill: $color-partner;
}
/* h5 headlines */
#searchbar h3.searchbar_categories {
    color: #000000;
    font-size: 19px;
    margin-bottom: 11px;
    margin-left: -7px;
    font-weight: bold;
    font-family: 'TTCommons-Light';
    display: inline-block;
    padding-right: 6px;
    cursor:grab;
     & span:hover {
      color: color(anyrent, $colors-theme);
      border-bottom: 1px solid red;
    }
    @media only screen and (max-width: 575px) {
      margin-bottom: 3px;
      margin-top: 3px;
      padding-right: 0px;
      padding: 5px;
      color: red;
      text-align: center;
      width: 100%;
      background: #f1f1f1;
  }
}
#searchbar h3.searchbar_categories.notification {
    position:relative;
}
#searchbar h3 span.notification {
    font-size: 16px;
    font-family: 'TTCommons-Regular', sans-serif;
    font-weight: 400;
    color: #f2f2f2;
    display: block;
    visibility: visible;
    position: absolute;
    width: 350px;
    z-index: 5;
    top: 28px;
    background-color: #828282e0;
    padding: 5px;
    border-radius: 8px;
}
.boxie.highlight_element{
  border-color: color(anyrent, $colors-theme);
  // move to front of others inputs
  z-index: 2;
}

.boxie span.notification {
  color: color(anyrent, $colors-theme);
  position: relative;
  top: 19px;
  padding: 3px;
}

.boxie {
  height: 67px;
  border: 1px solid #c7c7c7;
  margin-bottom: 5px;
  padding-top: 5px;
  // works as a fix for border collapsing
  border-collapse: collapse;
  margin-left: -1px;
}
.upper_line {
    text-transform: uppercase;
    color: #959595;
    font-size: 13px;
    font-weight: bold;
    display: inline-block;
    margin: 0.6em 1em 0 0.325em;
}
/* placeholders */

#searchbar .vdpComponent.vdpWithInput ::placeholder,
.vs__selected-options ::placeholder {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.vs__search::-webkit-search-decoration {
  -webkit-appearance: none;
}
/* vue-date-pick: overriding original components css */
/* TODO find a more scoped and nicer way to style the searchbar */
input.date_input,
#searchbar input.vs__search{
  height: 20px;
  padding-left: .125em;
  margin: 5px 0 0;
}
#searchbar .vs__selected {
  padding-left: .125em;
  margin: 4px 0 0 0;
  display: block;
  max-width: 80%;
  max-height: 20px;
  overflow: hidden;
}
#searchbar .vdpOuterWrap.vdpFloating {
  z-index: 3;
}
#searchbar .vdpComponent.vdpWithInput {
  width: 100%;
  box-sizing: border-box;
  margin-left: .425em;
  // padding-left: 1.1em;
}
#searchbar .vdpComponent.vdpWithInput > button{
  background: url("/assets/cross.png");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position-x: center;
  background-position-y: center;
}
#searchbar .vdpComponent.vdpWithInput :before {
  background: none;
  content:none;
}
#searchbar .vdpComponent.vdpWithInput > input{
  border: 0;
}
#searchbar .vdpComponent.vdpWithInput > input:focus {
  outline: none !important;
  // border: 2px solid color(anyrent, $colors-theme);
}
#searchbar .vdpComponent.vdpWithInput > input{
  font-family: 'TTCommons-Regular', sans-serif;
  width:90%;
  color: #333;
  padding: 0;
  // safari fix
  box-sizing: border-box;
  height: 22px;
  line-height: 22px;
  font-size: 1.45em;
  margin-top: 4px;
  padding-left: 3px;
}
#searchbar .vdpCell.today {
    color: color(anyrent, $colors-theme);
}
#searchbar .vdpTimeUnit > input:hover,
#searchbar .vdpTimeUnit > input:focus {
    border-bottom-color: color(anyrent, $colors-theme);
}

/* vue-select: overriding original components css */
.v-select.style-chooser .vs__dropdown-toggle,
.v-select.style-chooser .vs__dropdown-toggle {
  border: none;
}
input.vs__search{
  font-family: 'TTCommons-Regular', sans-serif;
  opacity: 0.4;
}
.v-select.style-chooser .vs__search {
  color: color(secondary, $colors-theme);
  z-index: 0;
}
.v-select .vs__clear,
.v-select .vs__open-indicator {
  fill: $color-matterhorn;
}
#searchbar .v-select.vs--searching .vs__selected {
  display: none;
}
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  background-color: rgba(0, 0, 0, 0) !important;
}
.style-chooser .vs__dropdown-menu li span.level-1 {
  font-family: 'TTCommons-Demibold', sans-serif;
  color: #000
}
.style-chooser .vs__dropdown-menu li span.level-2 {
  color: color(anyrent, $colors-theme);
  padding-left:1em;
}
.style-chooser .vs__dropdown-menu li span.level-3 {
  padding-left:2em;
}
.style-chooser .vs__dropdown-menu li span.level-4 {
  padding-left:3em;
}
.style-chooser .vs__dropdown-menu li span.level-5 {
  padding-left:4em;
}
.style-chooser .vs__dropdown-menu li.vs__dropdown-option--highlight > span {
  color: #fff;
}
</style>
<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
}
</style>
